import React from "react";

import * as Emojis from "../../assets/emoji";
import InstagramIcon from "../../assets/icons/instagram.svg";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import SoundcloudIcon from "../../assets/icons/soundcloud.svg";
import pageStyles from "./styles";

function CommingSoonPage() {
  const styles = pageStyles();
  const labelEmojis = Object.keys(Emojis);
  const randEmoji = () => {
    const indexRand = Math.floor(Math.random() * labelEmojis.length);
    return indexRand > -1 ? indexRand : 0;
  };

  const [currentEmoji, setCurrentEmoji] = React.useState(randEmoji());

  React.useEffect(() => {
    const callback = setTimeout(() => {
      setCurrentEmoji(randEmoji());
    }, 3000);

    return () => clearTimeout(callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmoji]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.containerLogo}>
          <img
            src={Emojis[labelEmojis[currentEmoji]]}
            className={styles.logo}
            alt="Dyxes"
          />
        </div>
        <p className={styles.description}>
          Hola 👋 soy <b> DYXES</b> y aquí te dejo mis links para que puedas
          conocer más sobre mi trabajo ¡Buena vibra y la mejor energía!
        </p>
        <a className={styles.link} href="https://froin.club">
          🤑 Únete a FROIN 🤑
        </a>
        {/* <a className={styles.link} href="https://froin.club/detail-video?id=IvqfCuhalVdEJ7zIFV2t">
          🤑 Dame play y gana $$$ 🤑
        </a> */}
        <a className={styles.link} href="https://www.dyxes.com/presskit">
          🥳 Ver mi Press Kit 🥳
        </a>
        <p className={styles.socialLabel}>Y encuéntrame en:</p>
        <div className={styles.row}>
          <a className={styles.linkIcon} href="https://www.youtube.com/@dyxes">
            <img src={YoutubeIcon} className={styles.icon} alt="Youtube" />
          </a>
          <a
            className={styles.linkIcon}
            href="https://www.instagram.com/djdyxes/"
          >
            <img src={InstagramIcon} className={styles.icon} alt="Instagram" />
          </a>
          <a
            className={styles.linkIcon}
            href="https://on.soundcloud.com/dHVwrzHsiT2sctnP9"
          >
            <img
              src={SoundcloudIcon}
              className={styles.icon}
              alt="Soundcloud"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CommingSoonPage;
